import moment from "moment";
import { request } from "../services/axios";

export const dateTimeStamp = (date, time) => {
  var dateANdTime = `${date} ${time}`;
  return moment.utc(dateANdTime).format();
};

export const TimeStamp = (date) => {
  var _date = `${date}`;
  return moment.utc(_date).format();
};

export const setOvrLimit = (data) => {
  switch (data) {
    case "ODI":
      return 50;
    case "T20":
      return 20;
    case "WODI":
      return 90;
  }
};

export const popDataa = (url, query) => {
  query
    ? window.open(
        `${url}/?${query}`,
        "newWin",
        "left=0,top=50,scrollbars=yes,menubar=no,toolbar=0,resizable=0,scrollbars=1,width=700px,height=700px"
      )
    : window.open(
        `${url}`,
        "newWin",
        "left=0,top=50,scrollbars=yes,menubar=no,toolbar=0,resizable=0,scrollbars=1,width=700px,height=700px"
      );
};

const formatPresentCustomDate = (presentDate) => {
  if (!presentDate) return;
  let date = new Date(presentDate);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let str = `${day < 10 ? "0" + day : day}-${
    month < 10 ? "0" + month : month
  }-${year}`;

  return str;
};

const formatCustomDateToApi = (customDate) => {
  let date = `${customDate.split("-")[2]}-${customDate.split("-")[1]}-${
    customDate.split("-")[0]
  }`;
  return date;
};

export { formatPresentCustomDate, formatCustomDateToApi };

export const GetUTCDateTime = (localdate) => {
  if (localdate) {
    let utcdatestr = new Date(localdate).toISOString().split("T");
    let datestr = utcdatestr[0];
    let timestr = utcdatestr[1];

    let year = datestr.split("-")[0];
    let month = datestr.split("-")[1];
    let day = datestr.split("-")[2];

    let hour = timestr.split(":")[0];
    let mint = timestr.split(":")[1];
    let seconds = timestr.split(":")[2].includes(".")
      ? timestr.split(":")[2].split(".")[0]
      : timestr.split(":")[2].replace("Z", "");
    let utcDateTime = new Date(
      year,
      Number(month) - 1,
      day,
      hour,
      mint,
      seconds
    );
    return utcDateTime;
  }
  var UtcDateString = new Date().toISOString();
  return UtcDateString;
};

export const SignIn = async (data) => {
  const res = await request({
    url: "",
    method: "post",
    data: {},
  });

  console.log(res);
};

export const GetSportId = (name) => {
  let id = "";
  switch (name) {
    case "football":
      id = "1";
      break;
    case "basketball":
      id = "23";
      break;
    case "hockey":
      id = "5";
      break;
    default:
      id = "1";
      break;
  }
  return id;
};
export const history = {
  navigate: null,
  location: null,
};
